import styled from "styled-components";

export const MainWrap = styled.div`
    width:100%;
    display:flex;
    align-items:flex-start;
    justify-content:Center;
    margin:5rem 0 0 0;

    @media (max-width:450px){
        margin: 2rem 0 0 0;
    }
` 

interface ConstraintProps {
    width?: string;
}

export const Constraint = styled.form<ConstraintProps>`
    width: ${p => p.width || '50%'};
    margin:0 auto;
    padding:2rem;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px rgba(9, 44, 76, 0.16);
    border-radius: 8px;
    text-align:center;
    color: #292929;

    > p {
        font-size:14px;
        line-height:27px;
        letter-spacing: 0.005em;
    }

    button {
        width:100%;
        color:#fff;
    }

    @media (max-width:450px){
        width:90%;
        padding:2rem 0%;
        box-shadow:none;
    }
`

export const InputWrap = styled.div`
    width:100%;
    margin:2rem 0;
`

export const InputField = styled.fieldset`
    width:94%;
    margin: 0 auto;
    border: 1px solid #D1D1D1;
    border-radius: 5px;
    text-align:left;
    margin: 0 0 1rem 0;

    > legend {
        font-size:13px;
        padding:0 0.5rem;
        text-align:left;
        font-weight:600;
        letter-spacing: 0.0125em;
    }

    > input {
        background:transparent;
        border:none;
        padding:0rem 0 0 0.6rem;
        font-size:14px;
        text-align:left;
        width:100%;
        color: #a5a5a5;

        @media (max-width:450px){
            font-size:16px;
            padding:0.1rem 0 0.1rem 0.6rem; 
        }
    }

    > select {
        background:transparent;
        border:none;
        padding:0rem 0 0 0.6rem;
        font-size:14px;
        text-align:left;
        width:100%;
        color: #a5a5a5;

        @media (max-width:450px){
            font-size:16px;
            padding:0.1rem 0 0.1rem 0.6rem; 
        }
    }

    > textarea {
        background:transparent;
        border:none;
        padding:0.4rem 0 0 0.6rem;
        font-size:14px;
        text-align:left;
        width:100%;
        color: #a5a5a5;
        height:5rem;
        resize:none;

        @media (max-width:600px){
            font-size:16px;
        }
    }

    @media (max-width:450px){
        width:92%;
    }
`

export const BelowText = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.0125em;
    color: #5D5B5B;
`

export const ContactFlex = styled.div`
    display:flex;
    align-items:flex-start;
    justify-content:center;
    gap:30px;
    width:80%;
    margin:0 auto;

    @media (max-width:728px){
        width:100%;
        flex-direction:Column;
    }
`

export const ContactInfo = styled.div`
    width:35%;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(9, 44, 76, 0.1);
    border-radius: 8px;

    @media (max-width:728px){
        width:90%;
        margin:0 auto;
    }
`

export const ContactInfoHead = styled.div`
    padding: 0.4rem 5%;
    background: #FFAB01;
    box-shadow: 0px 4px 20px rgba(9, 44, 76, 0.1);
    border-radius: 8px;
    letter-spacing: 0.15px;
    color: rgba(255, 255, 255, 0.87);
`

export const SpaceField = styled.div`
    margin: 2rem 0 2rem 0;
    padding: 0 10%;

    h4 {
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
    }

    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
    }

    > div {
        display:flex;
        align-items:Center;
        gap:10px;

        > p {
            width: 80%;
        }
    }
`