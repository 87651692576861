import styled from "styled-components";

export const PageWrap = styled.div`
  padding: 0 10%;
  position: relative;
  top: -45vh;

  p,
  li {
    line-height: 35px;
    font-weight: 400;
    font-family: Museo, "sams-serif";
    color: #757575;
  }
  @media (max-width: 768px) {
    top: -15vh;
  }
`;

export const Section = styled.div`
  padding: 0.5rem 0;
`;
export const SubSection = styled.div`
  padding: 0rem 4%;
`;
