import React from 'react';
import { DownloadWrap, ImageWrap } from '../../styles/download';

const DownloadSection = () => {
    return(
        <>
            <DownloadWrap>
                <div>
                    <img 
                        src='images/curves.png'
                        alt='Curves'
                    />
                    <p>
                        Mongoro is a product of Reef Financial Solutions Ltd., a financial technology company, not a bank. Banking services are provided by our partner banks.
                        Conditions and exceptions apply as per User Agreement
                    </p>
                    <ImageWrap>
                        <img 
                            src='images/googleplay.png'
                            alt='Google Play'
                        />
                        <img 
                            src='images/appstore.png'
                            alt='Google Play'
                        />
                    </ImageWrap>
                </div>
            </DownloadWrap>
        </>
    )
}

export default DownloadSection;