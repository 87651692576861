import React, { useEffect } from 'react';
import TermsHero from '../components/termsComp/termsHero';
import Footer from '../components/reusable/footer';

const Terms = () => {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return(
        <>
            <div className="main-widget">
                <TermsHero />
                <Footer />
            </div>
        </>
    )
} 

export default Terms;