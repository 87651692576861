import React, { useEffect } from 'react';
import ContactField from '../components/contactComp/contactField';
import ContactHero from '../components/contactComp/contactHero';
import DownloadSection from '../components/homeComps/downloadSection';
import Footer from '../components/reusable/footer';

const Contact = () => {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return(
        <>
            <div className="main-widget">
                <ContactHero />
                <ContactField />
                <img 
                    src='images/map.png'
                    alt='Map'
                    width='100%'
                    style={{
                        margin: '5rem 0'
                    }}
                />
                <DownloadSection />
                <Footer />
            </div>
        </>
    )
} 

export default Contact;