import { useContext, useEffect } from "react";
import "./style.css";
import { variableManager } from "../../context/VariableContext";
import { useNavigate } from "react-router";

const SelectApp = () => {

  const { selectProduct } = useContext(variableManager);
  return (
    <div className="app-selector-container">
      <button
        onClick={() => {
          window.open(
            selectProduct.actionFrom === "login"
              ? "https://app.mongoro.com/"
              : "https://app.mongoro.com/signup", "_blank"
          );
        }}
      >
        Personal account
      </button>{" "}
      <br />
      <button
        onClick={() => {
          window.open(
            selectProduct.actionFrom === "login"
              ? "https://business.mongoro.com/"
              : "https://business.mongoro.com/signup"
          );
        }}
      >
        Business account
      </button>
    </div>
  );
};

export default SelectApp;
