import React, { useEffect } from "react";
import Footer from "../components/reusable/footer";
import BusinessPrivacyHero from "../components/termsComp/businessPrivacyHero";

const BusinessPrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="main-widget">
        <BusinessPrivacyHero />
        <Footer />
      </div>
    </>
  );
};

export default BusinessPrivacyPolicy;
