import React, { useContext, useEffect, useState } from "react";
import {
  MainWrap,
  MainHead,
  LogoImage,
  NavigationSection,
  HeaderBtn,
  MobileMenuController,
  MobileModal,
} from "../../styles/headerStyle";
import { Link, NavLink } from "react-router-dom";
import { Button } from "../../styles/reusable";
import * as Icon from "react-feather";
import SelectApp from "../appSelector/SelectApp";
import { variableManager } from "../../context/VariableContext";

interface Props {
  logo: string;
  hideContact: boolean;
  width?: string;
  top?: string;
  mobileWidth?: string;
}
const AppHeader = ({ logo, hideContact, width, top, mobileWidth }: Props) => {
  const [showMobile, setShowMobile] = useState(false);

  const { selectProduct, setSelectProduct } = useContext(variableManager);

  //   useEffect(() => {
  //     return () => {
  //       setSelectProduct({ ...selectProduct, view: false });
  //     };
  //   }, []);

  return (
    <>
      <MainWrap>
        <MainHead>
          <Link to="/">
            <LogoImage
              width={width}
              mobileWidth={mobileWidth}
              top={top}
              src={logo}
              alt="Mongoro"
            />
          </Link>
          <NavigationSection>
            <p>
              Product
              <Icon.ChevronDown
                size={14}
                style={{
                  position: "relative",
                  top: "0.1rem",
                  left: "0.3rem",
                }}
              />
            </p>
            <p>Pricing</p>
            <p>Support</p>
          </NavigationSection>
          {hideContact ? null : (
            <HeaderBtn
            // to='/contact
            >
              {/* <a href="https://app.mongoro.com/"> */}
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectProduct({
                    ...selectProduct,
                    view: true,
                    actionFrom: "login",
                  });
                }}
                color="#3E3E0D"
              >
                Login
              </Button>
              {/* </a> */}
              {/* <a href="https://app.mongoro.com/signup"> */}
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectProduct({
                    ...selectProduct,
                    view: true,
                    actionFrom: "register",
                  });
                }}
                bg="var(--primary-color)"
                color="#fff"
              >
                Create an Account
              </Button>
              {/* </a> */}
            </HeaderBtn>
          )}
          <MobileMenuController
            mobileColor="#000"
            onClick={() => setShowMobile(true)}
          >
            <Icon.Menu size={20} strokeWidth={1} />
          </MobileMenuController>
        </MainHead>
        {selectProduct.view ? <SelectApp /> : null}
        {showMobile ? (
          <MobileModal>
            <i>
              <Icon.X onClick={() => setShowMobile(false)} />
            </i>
            <Link to="/">
              <LogoImage
                width={width}
                mobileWidth={mobileWidth}
                top={top}
                src={logo}
                alt="Mongoro"
              />
            </Link>
            <div>
              <p
                className={"navigation-text"}
                onClick={() => {
                  setShowMobile(false);
                }}
              >
                <NavLink to="/">Home</NavLink>
              </p>
              <p
                className="navigation-text"
                onClick={() => {
                  setShowMobile(false);
                }}
              >
                Products
              </p>
              <p
                className="navigation-text"
                onClick={() => {
                  setShowMobile(false);
                }}
              >
                Pricing
              </p>
              <p
                className="navigation-text"
                onClick={() => {
                  setShowMobile(false);
                }}
              >
                Support
              </p>
              <div>
                <Button bg="var(--primary-color)" color="#fff">
                  Join the waitlist
                </Button>
              </div>
            </div>
          </MobileModal>
        ) : null}
      </MainWrap>
    </>
  );
};

export default AppHeader;
