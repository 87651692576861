import React from "react";
import {
  HeroHeaderWrap,
  HeroMain,
  LeftHero,
  TextHeader,
  Breadcrumb,
} from "./../homeComps/styles";
import AppHeader from "../reusable/header";
import * as Icon from "react-feather";
import { PageWrap, Section } from "./style";

const PrivacyHero = () => {
  return (
    <>
      <HeroHeaderWrap>
        <AppHeader
          hideContact={false}
          logo={"/mongoro.png"}
          top="0.5rem"
          width="10rem"
          mobileWidth="8rem"
        />
        <HeroMain>
          <LeftHero>
            <div>
              <Breadcrumb>
                <p>Home</p>
                <Icon.ChevronRight />
                <p>Privacy Policy </p>
              </Breadcrumb>
              <TextHeader>
                Privacy &nbsp;
                <br />
                Policy
              </TextHeader>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  margin: "2rem 0 0 0",
                }}
              ></div>
            </div>
          </LeftHero>
        </HeroMain>
      </HeroHeaderWrap>
      <PageWrap>
        <div>
          <h2>Privacy Policy for Mongoro Business: </h2>
          <p>Last updated: March 01, 2024</p>
        </div>
        <Section>
          <h3>1. Introduction</h3>
          <p>
            Welcome to Reef Financial Solutions Ltd.
            <br />
            <b>Reef Financial Solutions Ltd </b>(“us”, “we”, or “our”) operates{" "}
            <b>mongoro.com</b> (hereinafter referred to as the “Service”).
            <br />
            Our Privacy Policy (the “Policy”) governs your visit to mongoro.com
            or its mobile app versions on the Google Playstore or iOS app store
            hosted on these links: [insert links], and explains how we collect,
            safeguard and disclose information that results from your use of the
            Service in accordance with the Nigeria Data Protection Regulation
            2019 (NDPR) and other data protections regulations provided herein.
            <br />
            We use your data to provide and improve Service. By using the
            Service, you agree to the collection and use of information in
            accordance with this Policy. Unless otherwise defined in this
            Policy, the terms used herein have the same meaning as in our Terms
            and Conditions.
            <br />
            Our Terms and Conditions (“Terms”) govern all use of Service and
            together with the Privacy Policy constitute your agreement with us
            (“Agreement”).
          </p>
        </Section>
        <Section>
          <h3>2. Definitions</h3>
          <p>For the purposes of this Privacy Policy:</p>
          <ul>
            <li>
              <b>ACCOUNT</b> means a unique account created for You to access
              our Service or parts of our Service.
            </li>
            <li>
              <b>AFFILIATE</b> means an entity that controls, is controlled by
              or is under common control with a party, where "control" means
              ownership of 50% or more of the shares, equity interest or other
              securities entitled to vote for election of directors or other
              managing authority.
            </li>
            <li>
              <b>APPLICATION</b> refers to Mongoro Business, the software
              program provided by the Company.
            </li>
            <li>
              <b>COMPANY</b> (referred to as either "the Company", "We", "Us" or
              "Our" in this Agreement) refers to Mongoro Business, 21 Blantyre
              Crescent, Wuse 2, Abuja.
            </li>
            <li>
              <b>COUNTRY</b> refers to: Nigeria.
            </li>
            <li>
              <b>DEVICE</b> means any device that can access the Service such as
              a computer, a cellphone or a digital tablet.
            </li>
            <li>
              <b>PERSONAL DATA</b> is any information that relates to an
              identified or identifiable individual.
            </li>
            <li>
              <b>SERVICE</b> refers to the Application.
            </li>
            <li>
              <b>SERVICE PROVIDER</b> means any natural or legal person who
              processes the data on behalf of the Company. It refers to
              third-party companies or individuals employed by the Company to
              facilitate the Service, to provide the Service on behalf of the
              Company, to perform services related to the Service or to assist
              the Company in analyzing how the Service is used.
            </li>
            <li>
              <b>USAGE DATA</b> refers to data collected automatically, either
              generated by the use of the Service or from the Service
              infrastructure itself (for example, the duration of a page visit).
            </li>
            <li>
              <b>YOU</b> means the individual accessing or using the Service, or
              the company, or other legal entity on behalf of which such
              individual is accessing or using the Service, as applicable.
            </li>
          </ul>
        </Section>
        <Section>
          <h3>3. Accessibility</h3>
          <p>
            Our privacy policy is accessible through the following publicly
            accessible URL:{" "}
            <a href="https://www.mongoro.com/business-privacy-policy">
              https://www.mongoro.com/business-privacy-policy
            </a>
          </p>
        </Section>
        <Section>
          <h3>4. Non-Editable</h3>
          <p>
            This privacy policy is non-editable to ensure the integrity and
            accuracy of the information provided.
          </p>
        </Section>
        <Section>
          <h3>5. Collecting and Using Your Personal Data</h3>
          <h4>Types of Data Collected</h4>
          <b>Personal Data</b>
          <p>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
            <li>Usage Data</li>
          </ul>
        </Section>
        <Section>
          <h3>6. Usage Data</h3>

          <p>Usage Data is collected automatically when using the Service.</p>
          <p>
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </p>
          <p>
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </p>
          <p>
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </p>
          <b>Information Collected while Using the Application</b>
          <p>
            While using Our Application, in order to provide features of Our
            Application, We may collect, with Your prior permission:
          </p>
          <ul>
            <li>Information regarding your location</li>
            <li>
              Pictures and other information from your Device's camera and photo
              library
            </li>
          </ul>
          <p>
            We use this information to provide features of Our Service, to
            improve and customize Our Service. The information may be uploaded
            to the Company's servers and/or a Service Provider's server or it
            may be simply stored on Your device.
          </p>
          <p>
            You can enable or disable access to this information at any time,
            through Your Device settings.
          </p>
          <h4>Use of Your Personal Data</h4>
          <p>The Company may use Personal Data for the following purposes:</p>
          <ul>
            <li>
              <b>To provide and maintain our Service</b>, including to monitor
              the usage of our Service.
            </li>
            <li>
              <b>To manage Your Account:</b> to manage Your registration as a
              user of the Service. The Personal Data You provide can give You
              access to different functionalities of the Service that are
              available to You as a registered user.
            </li>
            <li>
              <b>For the performance of a contract:</b> the development,
              compliance and undertaking of the purchase contract for the
              products, items or services You have purchased or of any other
              contract with Us through the Service.
            </li>
            <li>
              <b>To contact You:</b> To contact You by email, telephone calls,
              SMS, or other equivalent forms of electronic communication, such
              as a mobile application's push notifications regarding updates or
              informative communications related to the functionalities,
              products or contracted services, including the security updates,
              when necessary or reasonable for their implementation.
            </li>
            <li>
              <b>To provide You</b> with news, special offers and general
              information about other goods, services and events which we offer
              that are similar to those that you have already purchased or
              enquired about unless You have opted not to receive such
              information.
            </li>
            <li>
              <b>To manage Your requests:</b> To attend and manage Your requests
              to Us.
            </li>
            <li>
              <b>For business transfers:</b> We may use Your information to
              evaluate or conduct a merger, divestiture, restructuring,
              reorganization, dissolution, or other sale or transfer of some or
              all of Our assets, whether as a going concern or as part of
              bankruptcy, liquidation, or similar proceeding, in which Personal
              Data held by Us about our Service users is among the assets
              transferred.
            </li>
            <li>
              <b>For other purposes:</b> We may use Your information for other
              purposes, such as data analysis, identifying usage trends,
              determining the effectiveness of our promotional campaigns and to
              evaluate and improve our Service, products, services, marketing
              and your experience.
            </li>
          </ul>
          <p>
            We may share Your personal information in the following situations:
          </p>
          <ul>
            <li>
              <b>With Service Providers:</b> We may share Your personal
              information with Service Providers to monitor and analyze the use
              of our Service, to contact You.
            </li>
            <li>
              <b>For business transfers:</b> We may share or transfer Your
              personal information in connection with, or during negotiations
              of, any merger, sale of Company assets, financing, or acquisition
              of all or a portion of Our business to another company.
            </li>
            <li>
              <b>With Affiliates:</b> We may share Your information with Our
              affiliates, in which case we will require those affiliates to
              honor this Privacy Policy. Affiliates include Our parent company
              and any other subsidiaries, joint venture partners or other
              companies that We control or that are under common control with
              Us.
            </li>
            <li>
              <b>With business partners:</b> We may share Your information with
              Our business partners to offer You certain products, services or
              promotions.
            </li>
            <li>
              <b>With other users:</b> when You share personal information or
              otherwise interact in the public areas with other users, such
              information may be viewed by all users and may be publicly
              distributed outside.
            </li>
            <li>
              <b>With Your consent:</b> We may disclose Your personal
              information for any other purpose with Your consent.
            </li>
          </ul>
          <h4>Retention of Your Personal Data</h4>
          <p>
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </p>
          <p>
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </p>
          <h4>Transfer of Your Personal Data</h4>
          <p>
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </p>
          <p>
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </p>
          <p>
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </p>
          <h4>Delete Your Personal Data</h4>
          <p>
            You have the right to delete or request that We assist in deleting
            the Personal Data that We have collected about You.
          </p>
          <p>
            Our Service may give You the ability to delete certain information
            about You from within the Service.
          </p>
          <p>
            You may update, amend, or delete Your information at any time by
            signing in to Your Account, if you have one, and visiting the
            account settings section that allows you to manage Your personal
            information. You may also contact Us to request access to, correct,
            or delete any personal information that You have provided to Us.
          </p>
          <p>
            Please note, however, that We may need to retain certain information
            when we have a legal obligation or lawful basis to do so.
          </p>
          <h4>Disclosure of Your Personal Data</h4>
          <b>Business Transactions</b>
          <p>
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </p>
          <b>Law enforcement</b>
          <p>
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>
          <b>Other legal requirements</b>
          <p>
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </p>
          <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </li>
            <li>
              Protect the personal safety of Users of the Service or the public
            </li>
            <li>Protect against legal liability</li>
          </ul>
          <h4>Security of Your Personal Data</h4>
          <p>
            We implement stringent security measures to protect your personal
            and financial data from unauthorized access, disclosure, alteration,
            or destruction. These measures include:
          </p>
          <ul>
            <li>
              <b>Encryption:</b> All data transmission between your device and
              our servers is encrypted using industry-standard protocols.
            </li>
            <li>
              <b>Secure Storage:</b> Your information is stored securely on
              servers with restricted access, protected by firewalls, and other
              security mechanisms.
            </li>
            <li>
              <b>Regular Audits:</b> We conduct regular audits and assessments
              to ensure compliance with security standards and identify any
              potential vulnerabilities.
            </li>
            <li>
              <b>Employee Training:</b> Our staff undergo regular training on
              data security and privacy practices to ensure the safe handling of
              sensitive information.
            </li>
          </ul>
        </Section>
        <Section>
          <h3>7. Children's Privacy</h3>
          <p>
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </p>
          <p>
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </p>
        </Section>
        <Section>
          <h3>8. Links to Other Websites</h3>
          <p>
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
        </Section>
        <Section>
          <h3>9. Changes to this Privacy Policy</h3>
          <p>
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
        </Section>
        <Section>
          <h3>10. Payment System</h3>
          <p>
            Our payment system encompasses various features designed to
            streamline financial transactions and ensure the security of your
            funds:
          </p>
          <ul>
            <li>
              <b>Invoicing:</b> We offer a robust invoicing system that allows
              you to create, send, and track invoices efficiently. Your
              invoicing data is stored securely and accessible only to
              authorized personnel.
            </li>
            <li>
              <b>Savings Account Management:</b> Our platform provides tools for
              managing savings accounts, including depositing, withdrawing, and
              monitoring account activity. We employ encryption and access
              controls to protect your savings account information.
            </li>
            <li>
              <b>Mass/Salaries Payout:</b> For businesses, we facilitate mass
              payouts and salary disbursements securely and efficiently. Your
              employees' banking details are encrypted and stored securely, and
              payouts are processed with the highest level of security.
            </li>
            <li>
              <b>QR Codes:</b> We utilize QR codes for seamless and secure
              transactions. QR codes generated by our system contain encrypted
              data, ensuring that sensitive information is protected during
              scanning and processing.
            </li>
          </ul>
        </Section>
        <Section>
          <h3>11. User Involvement and Consent:</h3>
          <p>
            We believe in transparency and giving you control over your data.
            You have the right to access, update, or delete your personal
            information stored on our platform. By using our services, you
            consent to the collection, processing, and storage of your data as
            described in this Privacy Policy.
          </p>
        </Section>
        <Section>
          <h3>12. Updates to the Privacy Policy:</h3>
          <p>
            We may update this Privacy Policy periodically to reflect changes in
            our practices or legal requirements. We encourage you to review this
            policy regularly to stay informed about how we protect your privacy.
          </p>
        </Section>
        <Section>
          <h3>13. Contact Us</h3>
          <p>
            If you have any questions, concerns, or requests regarding our
            Privacy Policy or the handling of your personal information, please
            contact us at{" "}
            <a href="mailto:sales@mongoro.com">sales@mongoro.com</a>.
          </p>
          <p>
            By using Mongoro Business's services, you agree to the terms
            outlined in this Privacy Policy. Last updated: 1st March, 2024.
          </p>
          <p>
            Thank you for trusting Mongoro Business with your data privacy and
            security.
          </p>
        </Section>
        <div>
          <h2>Mongoro – Privacy Policy: </h2>
          <p>Effective date: 2023-02-01</p>
        </div>
        <Section>
          <h3>1. Introduction</h3>
          <p>
            Welcome to Reef Financial Solutions Ltd.
            <br />
            <b>Reef Financial Solutions Ltd </b>(“us”, “we”, or “our”) operates{" "}
            <b>mongoro.com</b> (hereinafter referred to as the “Service”).
            <br />
            Our Privacy Policy (the “Policy”) governs your visit to mongoro.com
            or its mobile app versions on the Google Playstore or iOS app store
            hosted on these links: [insert links], and explains how we collect,
            safeguard and disclose information that results from your use of the
            Service in accordance with the Nigeria Data Protection Regulation
            2019 (NDPR) and other data protections regulations provided herein.
            <br />
            We use your data to provide and improve Service. By using the
            Service, you agree to the collection and use of information in
            accordance with this Policy. Unless otherwise defined in this
            Policy, the terms used herein have the same meaning as in our Terms
            and Conditions.
            <br />
            Our Terms and Conditions (“Terms”) govern all use of Service and
            together with the Privacy Policy constitute your agreement with us
            (“Agreement”).
          </p>
        </Section>
        <Section>
          <h3>2. Definitions</h3>
          <ul>
            <li>
              <b>SERVICE</b> means the mongoro.com website operated by Reef
              Financial Solutions Ltd or its mobile app versions on the Google
              Playstore or the iOS App store.
            </li>
            <li>
              <b>PERSONAL</b> DATA means data about a living individual who can
              be identified from those data (or from those and other information
              either in our possession or likely to come into our possession).
            </li>
            <li>
              <b>USAGE DATA</b> is data collected automatically either generated
              using Service or from Service infrastructure itself (for example,
              the duration of a page visit).
            </li>
            <li>
              <b>COOKIES</b> are small files stored on your device (computer or
              mobile device).
            </li>
            <li>
              <b>DATA CONTROLLER</b> means a natural or legal person who (either
              alone or jointly or in common with other persons) determines the
              purposes for which and the way any personal data is, or is to be,
              processed. For the purpose of this Policy, we are the Data
              Controller of your data.
            </li>
            <li>
              <b>DATA PROCESSORS</b> (OR SERVICE PROVIDERS) mean any natural or
              legal person who processes the data on behalf of the Data
              Controller. We may use the services of various Service Providers
              in order to process your data more effectively.
            </li>
            <li>
              <b>DATA SUBJECT</b> is any living individual who is the subject of
              Personal Data.
            </li>
            <li>
              <b>THE USER</b> is the individual using our Service. The User
              corresponds to the Data Subject, who is the subject of Personal
              Data.
            </li>
          </ul>
        </Section>
        <Section>
          <h3>Information Collection and Use</h3>
          <p>
            {" "}
            We collect different types of information for various purposes to
            provide and improve our Service to you.
          </p>
        </Section>
        <Section>
          <h3>4. Types of Data Collected</h3>
          <p>
            <b>Personal Data</b>
            <br />
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you (“Personal Data”). Personally identifiable information
            may include, but is not limited to:
            <br />
            0.1. Email address
            <br />
            0.2. First name and last name
            <br />
            0.3. Phone number
            <br />
            0.4. Address, Country, State, Province, ZIP/Postal code, City
            <br />
            0.5. Cookies and Usage Data
            <br />
            We may use your Personal Data to contact you with newsletters,
            marketing or promotional materials and other information that may be
            of interest to you. You may opt out of receiving any, or all, of
            these communications from us by following the unsubscribe link.
          </p>
          <p>
            <b>Usage Data</b>
            <br />
            We may also collect information that your browser sends whenever you
            visit our Service or when you access Service by or through any
            device (“Usage Data”).
            <br />
            This Usage Data may include information such as your computer’s
            Internet Protocol address (i.e. IP address), browser type, browser
            version, the pages of our Service that you visit, the time and date
            of your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.
            <br />
            When you access Service with a device, this Usage Data may include
            information such as the type of device you use, your device unique
            ID, the IP address of your device, your device operating system, the
            type of Internet browser you use, unique device identifiers and
            other diagnostic data.
          </p>
          <p>
            <b>Location Data </b>
            We may use and store information about your location if you give us
            permission to do so. We use this data to provide features of our
            Service and to improve and customize our Service.
            <br />
            You can enable or disable location services when you use our Service
            at any time by way of your device settings.
          </p>
          <p>
            <b>Tracking Cookies Data </b>
            We use cookies and similar tracking technologies to track the
            activity on our Service and we hold certain information.
            <br />
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Other tracking technologies are
            also used such as beacons, tags and scripts to collect and track
            information and to improve and analyze our Service.
            <br />
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Service.
            <br />
            Examples of Cookies we use:
            <br />
            0.1. Session Cookies: We use Session Cookies to operate our Service.
            <br />
            0.2. Preference Cookies: We use Preference Cookies to remember your
            preferences and various settings.
            <br />
            0.3. Security Cookies: We use Security Cookies for security
            purposes.
            <br />
            0.4. Advertising Cookies: Advertising Cookies are used to serve you
            with advertisements that may be relevant to you and your interests.
          </p>
          <p>
            <b>Other Data </b>
            While using our Service, we may also collect the following
            information: sex, age, date of birth, place of birth, relevant ID
            card details, citizenship, registration at place of residence and
            actual address, telephone number (work, mobile), qualification,
            information on marital status, office location and other data.
          </p>
        </Section>

        <Section>
          <h3>5. Use of Data</h3>
          <p>
            Reef Financial Solutions Ltd uses the collected data for various
            purposes:
            <br />
            0.1. to provide and maintain our Service;
            <br />
            0.2. to notify you about changes to our Service;
            <br />
            0.3. to allow you to participate in interactive features of our
            Service when you choose to do so;
            <br />
            0.4. to provide better customer support;
            <br />
            0.5. to gather analysis or valuable information so that we can
            improve our Service;
            <br />
            0.6. to monitor the usage of our Service;
            <br />
            0.7. to detect, prevent and address technical issues;
            <br />
            0.8. to fulfil any other purpose for which you provide it;
            <br />
            0.9. to carry out our obligations and enforce our rights arising
            from any contracts entered between you and us, including for billing
            and collection;
            <br />
            0.10. to provide you with notices about your account and/or
            subscription, including expiration and renewal notices,
            email-instructions, etc.;
            <br />
            0.11. to provide you with news, special offers and general
            information about other goods, services and events which we offer
            that are like those that you have already purchased or enquired
            about unless you have opted not to receive such information;
            <br />
            0.12. in any other way we may describe when you provide the
            information;
            <br />
            0.13. for any other purpose with your consent.
          </p>
        </Section>

        <section>
          <h3> How We utilize Your Personal Information</h3>
          <p>
            Our collection of your personal data is aimed at providing you with
            an efficient, enjoyable, secure, and seamless customer experience.
            The purposes for which we may use your personal data include:
          </p>
          <p>
            0.1 Providing the requested services and support. <br />
            0.2 Processing transactions and sending transaction notices to
            relevant parties. <br />
            0.3 Verifying your identity. <br />
            0.4 Resolving disputes and troubleshooting problems. <br />
            0.5 Managing risk, detecting, preventing, and remediating fraud or
            illegal activities. <br />
            0.6 Ensuring compliance with policies and user agreements. <br />
            0.7 Improving services based on aggregate customer preferences.{" "}
            <br />
            0.8 Managing and protecting our information technology
            infrastructure. <br />
            0.9 Contacting you through provided contact details. <br />
            0.10 Notifying you about account activities, troubleshooting, and
            collecting fees. <br />
            0.11 Monitoring traffic patterns and site usage to enhance site
            design. <br />
            0.12 Recording and storing communications via phone, Skype, or
            website chat. <br />
            0.13 Personalizing your experience on our sites or in
            communications/advertising. <br />
            0.14 Providing customer service and responding to inquiries. <br />
            0.15 Sending important information, technical notices, updates, and
            security alerts. <br />
            0.16 Polling opinions through surveys or questionnaires. <br />
            0.17 Complying with legal obligations. <br />
            0.18 Enforcing or applying our policy. <br />
            0.19 Protecting our legitimate interests, privacy, property, or
            safety, and those of third parties.
          </p>
          <p>
            <b> Communication Monitoring:</b>
            We may monitor and record communications, including emails and phone
            conversations, for training, quality assurance, and legal and
            regulatory compliance.
          </p>
          <p>
            <b>Fraud Prevention:</b>
          </p>
          <p>
            We process personal data to prevent fraud, money laundering, and
            verify identity, as a legitimate interest and contractual
            requirement.
          </p>
          <p>
            <b>Data Analytics and Benchmarking:</b>
          </p>
          <p>
            Information generated during service use may be used for
            advertising, research, development, and providing new functionality,
            always ensuring pseudonymized and aggregated processing.
          </p>
          <p>
            <b>Your Rights as a Data Subject:</b>
          </p>
          <p>
            Your personal data is protected by rights under NDPR, including:
          </p>
          <p>
            Right to know how we use your data and access it. <br />
            0.1 Right to rectify, erase, or restrict processing of your data.{" "}
            <br />
            0.2 Right to object to processing based on legitimate interests.{" "}
            <br />
            0.3 Right to data portability. <br />
            0.4 Right to withdraw consent. <br />
            0.5 Right to object to automated decision-making. <br />
            0.6 Right to lodge a complaint with the supervisory authority.{" "}
            <br />
            <br />
            To exercise your rights, submit a written request through your
            registered channel, specifying the right you wish to exercise.
            Access the Data Subject Access Request (DSAR) portal on our website
            for additional options.
          </p>

          <p>
            For further information or to exercise data protection rights,
            contact us at support@mongoro.com. We aim to process subject access
            requests within 30 days and will communicate any extensions through
            existing channels, without cost. Note that existing communications
            may continue during the transitional period while preferences are
            updated.
          </p>
        </section>
        <Section>
          <h3>6. Retention of Data </h3>
          <p>
            We will retain your Personal Data only for as long as is necessary
            for the purposes set out in this Privacy Policy. We will retain and
            use your Personal Data to the extent necessary to comply with our
            legal obligations (for example, if we are required to retain your
            data to comply with applicable laws), resolve disputes, and enforce
            our legal agreements and policies.
            <br />
            We will also retain Usage Data for internal analysis purposes. Usage
            Data is generally retained for a shorter period, except when this
            data is used to strengthen the security or to improve the
            functionality of our Service, or we are legally obligated to retain
            this data for longer time periods.
          </p>
        </Section>
        <Section>
          <h3>7. Transfer of Data</h3>
          <p>
            Your information, including Personal Data, may be transferred to –
            and maintained on – computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ from those of your jurisdiction.
            <br />
            If you are located outside Nigeria and choose to provide information
            to us, please note that we transfer the data, including Personal
            Data, to Nigeria and process it there.
            <br />
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
            <br />
            Reef Financial Solutions Ltd will take all the steps reasonably
            necessary to ensure that your data is treated securely and in
            accordance with this Privacy Policy and no transfer of your Personal
            Data will take place to an organisation or a country unless there
            are adequate controls in place including the security of your data
            and other personal information.
          </p>
        </Section>
        <Section>
          <h3>8. Disclosure of Data</h3>
          <p>
            We may disclose personal information that we collect, or you
            provide:
            <br />
            0.1. Disclosure for Law Enforcement.
            <br />
            Under certain circumstances, we may be required to disclose your
            Personal Data if required to do so by law or in response to valid
            requests by public authorities.
            <br />
            0.2. Business Transaction.
            <br />
            If we or our subsidiaries are involved in a merger, acquisition or
            asset sale, your Personal Data may be transferred. When one of these
            events occurs, we will make reasonable efforts to notify you before
            your information is transferred or becomes subject to a different
            privacy policy.
            <br />
            0.3. Other cases. We may disclose your information also:
            <br />
            0.3.1. to our subsidiaries and affiliates;
            <br />
            0.3.2. to contractors, service providers, and other third parties we
            use to support our business;
            <br />
            0.3.3. to fulfill the purpose for which you provide it;
            <br />
            0.3.4. for the purpose of including your company’s logo on our
            website;
            <br />
            0.3.5. for any other purpose disclosed by us when you provide the
            information;
            <br />
            0.3.6. with your consent in any other cases;
            <br />
            0.3.7. if we believe disclosure is necessary or appropriate to
            protect the rights, property, or safety of the Company, our
            customers, or others.{" "}
          </p>
        </Section>
        <Section>
          <h3>9. Security of Data </h3>
          <p>
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.{" "}
          </p>
        </Section>
        <Section>
          <h3>
            Your Data Protection Rights Under General Data Protection Regulation
            (GDPR){" "}
          </h3>
          <p>
            If you are a resident of the European Union (EU) and European
            Economic Area (EEA), you have certain data protection rights,
            covered by GDPR.
            <br />
            We aim to take reasonable steps to allow you to correct, amend,
            delete, or limit the use of your Personal Data.
            <br />
            If you wish to be informed what Personal Data, we hold about you and
            if you want it to be removed from our systems, please email us at
            support@mongoro.com.
            <br />
            In certain circumstances, you have the following data protection
            rights:
            <br />
            0.1. the right to access, update or to delete the information we
            have on you;
            <br />
            0.2. the right of rectification. You have the right to have your
            information rectified if that information is inaccurate or
            incomplete;
            <br />
            0.3. the right to object. You have the right to object to our
            processing of your Personal Data;
            <br />
            0.4. the right of restriction. You have the right to request that we
            restrict the processing of your personal information;
            <br />
            0.5. the right to data portability. You have the right to be
            provided with a copy of your Personal Data in a structured,
            machine-readable and commonly used format;
            <br />
            0.6. the right to withdraw consent. You also have the right to
            withdraw your consent at any time where we rely on your consent to
            process your personal information;
            <br />
            Please note that we may ask you to verify your identity before
            responding to such requests. Please note, we may not be able to
            provide Service without some necessary data.
            <br />
            You have the right to complain to the Data Protection Authority
            about our collection and use of your Personal Data. For more
            information, please contact your local data protection authority in
            the European Economic Area (EEA).
          </p>
        </Section>
        <Section>
          <h3>
            Your Data Protection Rights under the Nigeria Data Protection
            Regulation Act (NDPR){" "}
          </h3>
          <p>
            Your data protection rights, described below, are covered by the
            NDPR, short for the NIgeria Data Protection Regulation Act. To find
            out more, visit the NDPR website. The requires a person or company
            in Nigeria that operates websites collecting personally identifiable
            information from Nigeria consumers to post a conspicuous privacy
            policy on its website stating exactly the information being
            collected and those individuals with whom it is being shared, and to
            comply with this policy.
            <br />
            According to the NDPR we agree to the following:
            <br />
            0.1. users can visit our site anonymously;
            <br />
            0.2. our Privacy Policy link includes the word “Privacy”, and can
            easily be found on the home page of our website;
            <br />
            0.3. users will be notified of any privacy policy changes on our
            Privacy Policy Page;
            <br />
            0.4. users are able to change their personal information by emailing
            us at support@mongoro.com.
            <br />
            Our Policy on “Do Not Track” Signals:
            <br />
            We honor Do Not Track signals and do not track, plant cookies, or
            use advertising when a Do Not Track browser mechanism is in place.
            Do Not Track is a preference you can set in your web browser to
            inform websites that you do not want to be tracked.
            <br />
            You can enable or disable Do Not Track by visiting the Preferences
            or Settings page of your web browser.
            <br />
            0.5. To delete your personal information. If you make this request,
            we will delete the personal information we hold about you as of the
            date of your request from our records and direct any service
            providers to do the same. In some cases, deletion may be
            accomplished through de-identification of the information. If you
            choose to delete your personal information, you may not be able to
            use certain functions that require your personal information to
            operate.
            <br />
            0.6. To stop selling your personal information. We don’t sell or
            rent your personal information to any third parties for any purpose.
            However, under some circumstances, a transfer of personal
            information to a third party, or within our family of companies,
            where required as part of our operational requirement. You are the
            only owner of your Personal Data and can request disclosure or
            deletion at any time.
            <br />
            If you submit a request to stop sharing your personal information,
            we will stop making such transfers.
            <br />
            Please note, if you ask us to delete or stop sharing your data, it
            may impact your experience with us, and you may not be able to
            access certain features or services which require the usage of your
            personal information to function. But under no circumstances, will
            we discriminate against you for exercising your rights.
            <br />
            To exercise your NDPR data protection rights described above, please
            send your request(s) by email: support@mongoro.com.
          </p>
        </Section>
        <Section>
          <h3>12. Service Providers</h3>
          <p>
            We may employ third party companies and individuals to facilitate
            our Service (“Service Providers”), provide Service on our behalf,
            perform Service-related services or assist us in analysing how our
            Service is used.
            <br />
            These third parties have access to your Personal Data only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>
        </Section>
        <Section>
          <h3>13. Analytics</h3>
          <p>
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </p>
        </Section>
        <Section>
          <h3>14. CI/CD Tools</h3>
          <p>
            We may use third-party Service Providers to automate the development
            process of our Service.
          </p>
        </Section>
        <Section>
          <h3>15. Behavioral Remarketing</h3>
          <p>
            We may use remarketing services to advertise on third party websites
            to you after you visited our Service. We and our third-party vendors
            use cookies to inform, optimise and serve ads based on your past
            visits to our Service.
          </p>
        </Section>
        <Section>
          <h3>16. Payments</h3>
          <p>
            We may provide paid products and/or services within Service. In that
            case, we use third-party services for payment processing (e.g.,
            payment processors).
            <br />
            We will not store or collect your payment card details. That
            information is provided directly to our third-party payment
            processors whose use of your personal information is governed by
            their Privacy Policy. These payment processors adhere to the
            standards set by PCI-DSS as managed by the PCI Security Standards
            Council, which is a joint effort of brands like Visa, Mastercard,
            American Express and Discover. PCI-DSS requirements help ensure the
            secure handling of payment information.
          </p>
        </Section>
        <Section>
          <h3>17. Links to Other Sites</h3>
          <p>
            Our Service may contain links to other sites that are not operated
            by us. If you click a third-party link, you will be directed to that
            third party’s site. We strongly advise you to review the Privacy
            Policy of every site you visit.
            <br />
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third-party sites or
            services.
          </p>
        </Section>
        <Section>
          <h3>18. Payments</h3>
          <p>
            Our Services are not intended for use by children under the age of
            18 (“Child” or “Children”).
            <br />
            We do not knowingly collect personally identifiable information from
            Children under 18. If you become aware that a Child has provided us
            with Personal Data, please contact us. If we become aware that we
            have collected Personal Data from Children without verification of
            parental consent, we take steps to remove that information from our
            servers.
          </p>
        </Section>
        <Section>
          <h3>19. Changes to This Privacy Policy</h3>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            <br />
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update
            “effective date” at the top of this Privacy Policy.
            <br />
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
        </Section>
        <Section>
          <h3>20.</h3>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us by email:{" "}
            <a href="mailto:support@mongoro.com">support@mongoro.com.</a>
          </p>
        </Section>
      </PageWrap>
    </>
  );
};

export default PrivacyHero;
