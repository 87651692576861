import React from 'react';
import { PeopleFlexedData, ServiceMain, CardSection } from '../../styles/services';
import { Button } from '../../styles/reusable';
import { Link } from 'react-router-dom';

const People = () => {
    return(
        <>
            <ServiceMain>
                <CardSection 
                    bg="url('images/pattern2.png')" 
                    imgSize='50rem' 
                    margin='0rem 0'
                >
                    <PeopleFlexedData
                        style={{
                            alignItems: 'center',
                        }}
                    >
                        <section>
                            <h3>
                                Hundreds of people just like you use Mongoro to run their business
                            </h3>
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '20px',
                                    margin: '2rem 0 0 0'
                                }}
                            >
                                <Link to='/waitlist'>
                                    <Button
                                        bg='var(--primary-color)'
                                        color='#fff'
                                    >Join waitlist</Button>
                                </Link>
                                <Link to='/contact'>
                                    <Button
                                        bg='transparent'
                                        color='#FFAB01'
                                        style={{
                                            border: '0.5px solid #FFAB01'
                                        }}
                                    >
                                        Contact Sales
                                    </Button>
                                </Link>
                                
                            </div>
                        </section>
                        <img 
                            src='/images/africa.png'
                            alt='Card'
                        />
                    </PeopleFlexedData>
                </CardSection>
            </ServiceMain>
        </>
    )
}

export default People;