import styled from "styled-components";

export const HeroHeaderWrap = styled.div`
  position: relative;
  height: 100vh;
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    background: url("/images/hero.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  @media (max-width: 450px) {
    height: auto;
  }
`;

export const BroadBg = styled.div`
  position: absolute;
  top: -5rem;
  right: 17%;
  width: 450px;
  height: 450px;
  background: radial-gradient(
    50.04% 46.62% at 50% 50%,
    rgba(255, 213, 0, 0.6) 0%,
    rgba(255, 213, 0, 0) 100%
  );

  @media (max-width: 450px) {
    top: 25rem;
    right: 0%;
  }
`;

export const HeroMain = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0px;
  width: 84%;
  margin: 0 auto;
  padding: 2rem 8%;

  @media (max-width: 1440px) {
    gap: 30px;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    gap: 0;
    padding: 2rem 0%;
  }
`;

export const LeftHero = styled.div`
  background: transparent;
  display: flex;
  align-items: center;
  width: 50%;

  > div {
    width: 100%;
    padding: 0rem 0 0 1rem;
    line-height: 0px;
    color: #fff;

    @media (max-width: 450px) {
      padding: 0rem 0% 2rem 0%;
    }
  }

  @media (max-width: 1000px) {
    margin: 0 auto;
    width: 70%;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const RightHero = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0 0 0;
  position: relative;

  > img {
    width: 30rem;
  }

  @media (max-width: 450px) {
    width: 100%;
    margin: 5rem 0 0 0;

    > img {
      width: 100%;
    }
  }

  @media (max-width: 1000px) {
    margin: 7rem 0 0 0;
  }
`;

export const UpperBound = styled.img`
  position: absolute;
  top: -2rem;
  right: -2rem;
  width: 250px;

  @media (max-width: 450px) {
    width: 180px;
    right: -1rem;
  }
`;

export const BelowBound = styled.img`
  position: absolute;
  bottom: 3rem;
  left: 4rem;
  width: 250px;

  @media (max-width: 450px) {
    width: 180px;
    left: 0rem;
  }
`;

export const ClassyWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
  top: 1rem;

  > div {
    width: 75px;
    height: 12px;
    background: #a2ad1f;
  }

  > h3 {
    letter-spacing: 0.3em;
    color: #3e3e0d;
    font-size: 20px;
    font-weight: 400;
  }

  @media (max-width: 450px) {
    > h3 {
      font-size: 16px;
    }
  }
`;

export const TextHeader = styled.h1`
  font-weight: 800;
  font-size: 60px;
  line-height: 67px;
  color: #000;

  @media (max-width: 1000px) {
    font-size: 25px;
  }

  @media (max-width: 600px) {
    font-size: 40px;
    line-height: 45px;
  }
`;

export const SupplText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #000;

  @media (max-width: 450px) {
    font-size: 14px;
    line-height: 30px;
  }
`;

export const TextField = styled.div`
  padding: 0.7rem;
  background: var(--main-bg);
  color: #fff;
  box-shadow: 0px 8px 40px rgba(9, 44, 76, 0.16);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > input {
    width: 16rem;
    font-size: 14px;
  }

  @media (max-width: 450px) {
    width: 94%;
    padding: 0.7rem 3%;
    margin: 2rem 0 0 0;

    > input {
      width: 50%;
      font-size: 16px;
    }
  }
`;

export const Breadcrumb = styled.div`
  display: flex;
  align-items: Center;
  gap: 10px;
  font-size: 12px;
  color: #000;
`;
