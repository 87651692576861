import styled from "styled-components";

export const MainWrap = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    gap:0;
    position:relative;
    z-index:10000;
`

interface LogoProps {
    width: any;
    top: any;
    mobileWidth: any;
}

export const LogoImage = styled.img<LogoProps>`
    width: ${p => p.width || '5rem'};
    position:relative;
    top: ${p => p.top || '0'};

    @media (max-width:450px){
        width: ${p => p.mobileWidth || '3rem'};
    }
`;

interface PropsArgs {
    mobileBg?: string;
    mobileColor?: string;
}

export const MainHead = styled.div<PropsArgs>`
    width:84%;
    padding: 1.2rem 8% 0.2rem 8%;
    display:flex;
    align-items:Center;
    justify-content:space-between;
    margin: 0 auto;

    > div {
        display:flex;
        align-items:Center;
        justify-content:center;
        gap:100px;
    }

    @media (max-width:728px){
        > div {
            display:none;
        }
    }

    @media (max-width:450px){
        background: ${p => p.mobileBg || 'transparent'};
        color: ${p => p.mobileColor || 'var(--main-bg)'};
    }
    
`;

export const MobileMenuController = styled.section<PropsArgs>`
    display:none;
    color: ${p => p.mobileColor || '#fff'};

    @media (max-width:728px){
        display:block;   
    }
`;

export const MobileModal = styled.div`
    position: fixed;
    width:86%;
    height:100%;
    top:0;
    left:0;
    background:#fff;
    padding:3rem 7%;

    > i {
        position:absolute;
        top:2rem;
        right:2rem;
    }
`

export const NavigationSection = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    gap:30px;

    > p {
        font-weight: 500;
        font-size: 14px;
        line-height: 168%;
        letter-spacing: 0.005em;
        color: #3E3E0D;
    }

    @media (max-width:728px){
        display:none;
    }
`

export const HeaderBtn = styled.section`
    display: flex;
    gap:30px;

    @media (max-width:728px){
        display:none;
    }
`