import React from 'react';
import { HeroHeaderWrap, HeroMain, LeftHero, TextHeader, SupplText, Breadcrumb } from './../homeComps/styles';
import AppHeader from '../reusable/header';
import * as Icon from 'react-feather';

const ContactHero = () => {

    const date = new Date();

    return(
        <>
            <HeroHeaderWrap>
                <AppHeader 
                    hideContact={false} 
                    logo={'/mongoro.png'} 
                    top='0.5rem'
                    width='10rem'
                    mobileWidth='8rem'
                />
                <HeroMain>
                    <LeftHero>
                        <div>
                            <Breadcrumb>
                                <p>Home</p>
                                <Icon.ChevronRight />
                                <p>Contact Us</p>
                            </Breadcrumb>
                            <TextHeader>
                                Contact us
                            </TextHeader>
                            <SupplText>
                                Mongoro makes it easy to get paid, process orders quickly, and manage your finances more effectively. Take your business to new heights with our solutions made just for you!
                            </SupplText>
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '20px',
                                    margin: '2rem 0 0 0'
                                }}
                            >
                            </div>
                        </div>
                    </LeftHero>
                </HeroMain>
            </HeroHeaderWrap>
            
        </>
    )
}

export default ContactHero;