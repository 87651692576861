import React from 'react';
import { FlexedData, IconHolder, ServiceMain, TextButton, CardSection } from '../../styles/services';
import { Button } from '../../styles/reusable';
import { Link } from 'react-router-dom';

const Services = () => {
    return(
        <>
            <ServiceMain>
                <h2>
                    Card Readers made for your Business
                </h2>
                <FlexedData
                    style={{
                        margin: '4rem 5%'
                    }}
                >
                    {
                        data.map((item, index) => (
                            <div key={index}>
                                <IconHolder>
                                    <img 
                                        src={`${item.icon}`}
                                        alt='Icon'
                                    />
                                </IconHolder>
                                <h3>{item.title}</h3>
                                <p>{item.text}</p>
                                <TextButton
                                    color={item.ready ? 'var(--primary-color)' : ''}
                                    border={item.ready ? '1px solid var(--primary-color)' : '1px solid #a2ad1f'}
                                >
                                    <Link to={item.ready ? '/contact' : '#'}>
                                        <h4>{item.availText}</h4>
                                    </Link>
                                </TextButton>
                            </div>
                        ))
                    }
                </FlexedData>
                <CardSection>
                    <FlexedData
                        style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            // gap: '150px'
                        }}
                    >
                        <img 
                            src='/images/atm.png'
                            alt='Card'
                        />
                        <section>
                            <h2>
                                Start Accepting Card Payments  <span
                                    style={{
                                        color: '#FFAB01'
                                    }}
                                >today</span>
                            </h2>
                            <p>Accept cards for just a 1.0% fee per transaction – anytime, anywhere!</p>
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '20px',
                                    margin: '2rem 0 0 0'
                                }}
                            >
                                <Link to='/waitlist'>
                                    <Button
                                        bg='var(--primary-color)'
                                        color='#fff'
                                    >Join waitlist</Button>
                                </Link>
                                <Link to='/contact'>
                                    <Button
                                        bg='transparent'
                                        color='#FFAB01'
                                        style={{
                                            border: '0.5px solid #FFAB01'
                                        }}
                                    >Contact Sales</Button>
                                </Link>
                            </div>
                        </section>
                    </FlexedData>
                </CardSection>
                
            </ServiceMain>
        </>
    )
}

export default Services;

const data = [
    {
        title: 'Mongoro Dara',
        text: 'The perfect 4-in-1 card reader that uses the free Mongoro app on your smartphone to accept payments. Its the size of a small calculator with the power to scale your business significantly!',
        icon: '/images/dara.png',
        availText: 'Coming Soon',
        ready: false
    },
    {
        title: 'Mongoro Sade',
        text: 'Easily accept payments with this powerful card machine without needing to use the free Mongoro app on your smartphone to accept payments. This devices is ready for all your transactions!',
        icon: '/images/sade.png',
        availText: 'Coming Soon',
        ready: false
    },
    {
        title: 'Mongoro Amal',
        text: 'A sleek, Android digital POS with an intuitive touchscreen that lets you do more than just accept payments. See all your sales history and print out customer receipts from one device',
        icon: 'images/amal.png',
        availText: 'Contact Sales',
        ready: true
    }
]