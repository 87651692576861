import React, { useState } from 'react';
import { BelowText, Constraint, InputField, InputWrap, MainWrap } from './styles';
import { Button } from '../../styles/reusable';
import Alert from '../reusable/alert';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';

const ContactField = () => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [onProcess, setOnProcess] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('')

    const inputData = [
        {
            name: 'Name',
            type: 'text',
            value: name,
            setFunc: setName,
        },
        {
            name: 'Email Address',
            type: 'email',
            value: email,
            setFunc: setEmail
        },
    ]

    const body = {
        name,
        email
    }

    const handleJoin = (e:any) => {
        e.preventDefault();
        setOnProcess(true)
        axios.post(`${process.env.REACT_APP_API_URL}/mongoro_waitlist/create`, body).then((res) => {
            setSuccess(true);
            setOnProcess(false);
            setName('');
            setEmail('');
        }).catch((err) => {
            setError(err.response.data.msg);
            setOnProcess(false)
            setTimeout(() => {
                setError('')
            }, 4000)
        })
    }

    return(
        <>
            {
                success ? 
                    <Alert 
                        closeFunc={() => setSuccess(false)}
                        img='./icons/success.png'
                        message='Congratulations!'
                        miniMessage='You have successfully joined our wailist. Wait for more updates from us!'
                    />

                    : null
            }

            {
                error && error !== '' ? 
                    <Alert 
                        closeFunc={() => setError('')}
                        img='./icons/warning.png'
                        message='An error occured!'
                        miniMessage={error}
                    />

                    : null
            }
            <MainWrap>
                <Constraint width='30%' onSubmit={(e) => handleJoin(e)}>
                    <h3>Join the Waitlist</h3>
                    <p>We're coming soon...
                        <br />
                        Looking to grow your business exponentially? Subscribe to get a notification as we launch 🚀</p>
                    {/* Input Section */}
                    <InputWrap>
                        {
                            inputData.map((item, index) => (
                                <InputCard
                                    key={index}
                                    name={item.name}
                                    type={item.type}
                                    setFunc={item.setFunc}
                                    value={item.value}
                                />
                            ))
                        }
                    </InputWrap>
                    <Button
                        bg='var(--primary-color)'
                        color='#fff'
                        type='submit'
                    >{onProcess ? <ClipLoader color='#fff' size={14} /> : 'Submit'}</Button>
                    <BelowText>
                        By submitting this form, I confirm that I have read and understood mongoro's 
                        <span
                            style={{
                                textDecoration: 'underline'
                            }}
                        > Privacy Statement.</span>
                    </BelowText>
                </Constraint>
            </MainWrap>
        </>
    )
}

export default ContactField;

const InputCard = ({name, type, value, setFunc} : any) => {
    return(
        <InputField>
            <legend>{name}</legend>
            <input required type={type} value={value} onChange={(e) => setFunc(e.target.value)} />
        </InputField>
    )
}