import { createContext, useState } from "react";

export const variableManager = createContext();
export default function VaraiableContext({ children }) {
  const [selectProduct, setSelectProduct] = useState({
    view: false,
    actionFrom:"",
    actionTo:"",
    fn: "",
  });

 const exportData = {
   selectProduct,
   setSelectProduct,
 };
  return <variableManager.Provider value={exportData} >{children}</variableManager.Provider>;
}
