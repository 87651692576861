import React from 'react';
import { HeroHeaderWrap, BroadBg, HeroMain, LeftHero, TextHeader, SupplText, TextField, RightHero, UpperBound, BelowBound} from './styles';
import { Button } from '../../styles/reusable';
import appleApp from "../../../public/icons/appleApp.svg"
import androidApp from "../../../public/icons/googleApp.svg"

import AppHeader from '../reusable/header';
import { Link } from 'react-router-dom';

const HomeHero = () => {

    const date = new Date();
    let year = date.getFullYear();

    return(
        <>
            <HeroHeaderWrap>
                <AppHeader 
                    hideContact={false} 
                    logo={'/mongoro.png'} 
                    top='0.5rem'
                    width='10rem'
                    mobileWidth='8rem'
                />
                <HeroMain>
                    <LeftHero>
                        <div>
                            <TextHeader>
                                Simple <br /> Payments for Africa’s <span
                                    style={{
                                        color: 'var(--primary-color)'
                                    }}
                                >Markets</span>
                            </TextHeader>
                            <SupplText>
                                Mongoro makes it easy to get paid, process orders quickly, and manage your finances more effectively. Take your business to new heights with our solutions made just for you!
                            </SupplText>
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '20px',
                                    margin: '2rem 0 0 0'
                                }}
                            >
                                <a href='https://onelink.to/7bwe3b'>

                                    {/* <Button
                                        bg='var(--primary-color)'
                                        color='#fff'
                                    >Join waitlist</Button> */}
                                    <img src="/icons/appleApp.svg" alt="get it on App Store" />
                                </a>
                                <a href='https://onelink.to/7bwe3b'>
                                <img src="/icons/googleApp.svg" alt="get it on Google PlayStore" />
                                    {/* <Button
                                        bg='transparent'
                                        color='#FFAB01'
                                        style={{
                                            border: '0.5px solid #FFAB01'
                                        }}
                                    >Contact Sales</Button> */}
                                </a>
                            </div>
                        </div>
                    </LeftHero>
                    <RightHero>
                        <img 
                            src='/images/splash.png'
                            alt='Mongoro'
                        />
                    </RightHero>
                </HeroMain>
            </HeroHeaderWrap>
            
        </>
    )
}

export default HomeHero;