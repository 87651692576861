import styled from 'styled-components';

export const DownloadWrap = styled.div`
    padding: 5rem 5%;
    background: #FFF8EB;

    > div {
        width:45%;
        background: url('/images/pattern1.png');
        background-size:cover;
        background-repeat:no-repeat;
        border-radius: 30px;
        padding: 1rem 15%;
        position:relative;
        display:flex;
        flex-direction:column;
        align-items:Center;
        color: #fff;
        margin: 0 auto;

        > img {
            position:absolute;
            top: 0;
            left:0;
        }

        p {
            font-family: var(--maint-font), 'sans-serif';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 30px;
            text-align: center;
            color: #F9FBFB;

            @media (max-width:728px){
                font-size:13px;
            }
        }
        
    }

    @media (max-width:728px){
        > div {
            width:92%;
            padding:1rem 4%;

            > img {
                display:none;
            }
        }
    }
`

export const ImageWrap = styled.div`
    width: 60%;
    margin:2rem auto;
    display:flex;
    align-items:Center;
    justify-content:center;
    gap:20px;

    > img {
        height: 3rem;
    }

    @media (max-width:728px){

        width:100%;

        > img {
            height: 2rem;
        }
    }
`