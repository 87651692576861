import React from 'react';
import { BlogGrid, BlogMain, DateText, TitleText } from '../../styles/blog';
import { TextButton } from '../../styles/services';
import * as Icon from 'react-feather';

const BlogSection = () => {
    return(
        <>
            <BlogMain>
                <h2>
                    Learn from our community how you can use Mongoro to 
                    <span
                        style={{
                            color: '#ffab01'
                        }}
                    > run and grow </span>
                    your business
                </h2>
                <BlogGrid>
                    {
                        data.slice(0, 3).map((item, index) => (
                            <div key={index}>
                                <img 
                                    src={item.img}
                                    alt='Blog'
                                />
                                <div>
                                    <TitleText>{item.title}</TitleText>
                                    <DateText>{item.date} | 19min read</DateText>
                                    <TextButton
                                        color='#FFAB01'
                                    >
                                        <h4>Read More</h4>
                                        <i>
                                            <Icon.ArrowRight color='#FFAB01' size={14} />
                                        </i>
                                </TextButton>
                                </div>
                            </div>
                        ))
                    }
                </BlogGrid>
            </BlogMain>
        </>
    )
}

export default BlogSection;

export const data = [
    {
        id: 1,
        title: '5 steps to register a business name',
        date: '27 Jan, 2022',
        img: '/images/people.webp'
    },
    {
        id: 2,
        title: '15 tricks to improve sales',
        date: '27 Jan, 2022',
        img: '/images/woman3.webp'
    },
    {
        id: 3,
        title: 'A step by step guide to starting your online business',
        date: '27 Jan, 2022',
        img: '/images/devices.webp'
    },
    {
        id: 4,
        title: 'How one Webflow user grew his single person consultancy from $0-100K in 14 months',
        date: '27 Jan, 2022',
        img: '/images/devices.webp'
    },
    {
        id: 5,
        title: 'How one Webflow user grew his single person consultancy from $0-100K in 14 months',
        date: '27 Jan, 2022',
        img: '/images/woman3.webp'
    },
    {
        id: 6,
        title: 'How one Webflow user grew his single person consultancy from $0-100K in 14 months',
        date: '27 Jan, 2022',
        img: '/images/people.webp'
    },
]