import React, { useState } from 'react';
import { Constraint, InputField, InputWrap, ContactFlex, ContactInfo, ContactInfoHead, SpaceField } from './styles';
import { Button } from '../../styles/reusable';
import { ClipLoader } from 'react-spinners';
import emailjs from 'emailjs-com';
import Alert from '../reusable/alert';
import * as Icon from 'react-feather';

const ContactField = () => {

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [onProcess, setOnProcess] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<any>('');

    const inputData = [
        {
            name: 'Name',
            type: 'text',
            required: true,
            value: fullName,
            setFunc: setFullName
        },
        {
            name: 'Email Address',
            type: 'email',
            required: true,
            value: email,
            setFunc: setEmail
        },
    ]

    const submit = (e:any) => {
        e.preventDefault();
        setOnProcess(true);
        setTimeout(() =>{
            setOnProcess(false);
        }, 30000)
        if (fullName && subject && email && message) {
            const serviceId:any = process.env.REACT_APP_EMAILJS_SERVICE_ID;
            const templateId:any = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
            const userId:any = process.env.REACT_APP_EMAILJS_USER_ID;
            const templateParams = {
                fullName,
                email,
                subject,
                message,
                compEmail: process.env.REACT_APP_EMAILJS_COMP_EMAIL,
                phone
            };

            emailjs.send(serviceId, templateId, templateParams, userId)
                .then(response => {
                    setOnProcess(false);
                    if (response.status === 200){
                        setSuccess(true);
                        setFullName('');
                        setEmail('')
                        setPhone('')
                        setSubject('')
                        setMessage('')
                    }
                })
                .then(error => {
                    setOnProcess(false);
                    setError(error);
                    setTimeout(() => {
                        setError('');
                    }, 4000)
                });
        } else {
            setOnProcess(false);
            setError('Please fill in all fields.');
            setTimeout(() => {
                setError('')
            }, 4000)
        }
    }

    return(
        <>
            {
                success ? 
                    <Alert 
                        closeFunc={() => setSuccess(false)}
                        img='/icons/success.png'
                        message='Message sent!'
                        miniMessage='Thank you for sending this mail, you will recieve a feedback shortly'
                    />

                    : null
            }

            {
                error && error !== '' ? 
                    <Alert 
                        closeFunc={() => setError('')}
                        img='/icons/warning.png'
                        message='An error occured!'
                        miniMessage={error}
                    />

                    : null
            }
            
            <ContactFlex>
                <ContactInfo>
                    <ContactInfoHead>
                        <h4>Contact Us</h4>
                    </ContactInfoHead>
                    <SpaceField>
                        <h4>Office Address:</h4>
                        <div>
                            <Icon.MapPin color='#FFAB01' />
                            <p>21 Blantyre Crescent, Wuse 2. Abuja</p>
                        </div>
                        <h4>Contact Info:</h4>
                        <div>
                            <Icon.Phone color='#FFAB01' />
                            <p>+2348033550170</p>
                        </div>
                        <div>
                            <Icon.Send color='#FFAB01' />
                            <p>sales@mongoro.com</p>
                        </div>
                    </SpaceField>
                </ContactInfo>
                <Constraint>
                    <form
                        onSubmit={e => submit(e)} 
                    >
                        <InputWrap>
                            {
                                inputData.map((item, index) => (
                                    <InputCard
                                        key={index}
                                        name={item.name}
                                        type={item.type}
                                        isRequired={item.required}
                                        value={item.value}
                                        setFunc={item.setFunc}
                                    />
                                ))
                            }
                            <InputField>
                                <legend>
                                    {'Reason for reaching out'}
                                    <span style={{
                                        color: '#c82b38'
                                    }}>*</span>
                                </legend>
                                <select  
                                    required={true}
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                >
                                    <option>mPos</option>
                                    <option>Mongoro Terminals</option>
                                    <option>Duration to get a Terminal</option>
                                    <option>Customer Care Services</option>
                                    <option>Pricing</option>
                                </select>
                            </InputField>
                            <InputField>
                                <legend>
                                    {'Message'}
                                    <span style={{
                                        color: '#c82b38'
                                    }}>*</span>
                                </legend>
                                <textarea 
                                    placeholder='Leave a Message for us' 
                                    required={true}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                ></textarea>
                            </InputField>
                        </InputWrap>
                        <Button
                            bg='var(--primary-color)'
                            type='submit'
                            disabled={onProcess}
                            style={
                                onProcess ? {cursor: 'not-allowed'} : {}
                            }
                            >
                                {
                                    onProcess ? <ClipLoader size={23} color='#fff' />
                                    :
                                    'Send Message'
                                }
                        </Button>
                    </form>
                </Constraint>
            </ContactFlex>
        </>
    )
}

export default ContactField;

const InputCard = ({name, type, value, setFunc, isRequired} : any) => {
    return(
        <InputField>
            <legend>
                {name} 
                <span style={{
                    color: '#c82b38'
                }}>{isRequired ? '*' : ''}</span>
            </legend>
            <input 
                type={type} 
                required={isRequired}
                value={value}
                onChange={e => setFunc(e.target.value)}
            />
        </InputField>
    )
}