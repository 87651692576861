import styled from "styled-components";

interface PropsArgs {
    bg?: string;
    color?: string;
    border?: string;
    padding?: string;
}

interface FlexedAllAlignedProps {
    gap?: string;
    top?: string;
}

export const Button = styled.button<PropsArgs>`
    background: ${p => p.bg || 'transparent'};
    color: ${p => p.color || 'var(--text-black)'};
    border: ${p => p.border || 'none'};
    padding: ${p => p.padding || '0.7rem 1.5rem'}
`;

export const FlexedAllAligned = styled.div<FlexedAllAlignedProps>`
    display:flex;
    align-items:center;
    position:relative;
    gap: ${p => p.gap || 0};
    top: ${p => p.top || 0};
`;

export const GenericTextHeader = styled.h1`
    font-size:45px;
    font-weight:700;
`