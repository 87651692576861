import styled from "styled-components";

export const ServiceMain = styled.div`
    margin: 5rem auto 0 auto;
    padding: 0rem 0%;
    text-align:center;

    > h2 {
        font-style: normal;
        font-weight: 800;
        font-size: 35px;
        line-height: 48px;
        color: #000000;
        width:40%;
        margin:0 auto;
    }

    > p {
        padding:0 17%;
        font-weight: 300;
        font-size: 16px;
        line-height: 30px;
    }

    @media (max-width:600px){
        margin: 5rem auto 0 auto;
        padding: 2rem 0%;

        > h2 {
            font-size:25px;
            line-height:35px;
            width:90%;
        }

        > p {
            padding:0 5%;
        }
    }
`

export const FlexedData = styled.div`
    display:flex;
    justify-content:space-between;
    gap:66px;
    margin:5rem 0 0 0;
    padding:0 0%;

    > section {
        width:40%;
        text-align:left;

        h2,h3 {
            font-style: normal;
            font-weight: 800;
            font-size: 35px;
            line-height: 55px;
            color: #000000;
        }

        h3 {
            font-size:25px;
            line-height: 30px;
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 25px;
            letter-spacing: 0.15px;
            color: #000000;
        }
    }

    > div {
        flex: 1;
        margin:0 1%;
        padding:1rem;
        display:flex;
        flex-direction:column;
        align-items:center;
        background: #FFFFFF;
        box-shadow: 0px 0px 5px rgba(9, 44, 76, 0.05);
        border-radius: 8px;

        > h3 {
            font-weight: 700;
            font-size: 22px;
            line-height: 32px;
            letter-spacing: 0.15px;
            color: #FFAB01;
        }

        > p {
            font-size: 14px;
            line-height: 25px;
            text-align: center;
            letter-spacing: 0.15px;
            color: #000000;
        }
    }

    @media (max-width:900px){
        flex-direction:column;
        gap:50px;

        > section {
            width:90%;
            padding:0 5%;

            > h2 {
                line-height:45px;
            }
        }
    }
`

export const PeopleFlexedData = styled.div`
    display:flex;
    justify-content:flex-end;
    gap:-20px;
    margin:2rem 0 0 0;
    padding:0 5%;
    position:relative;

    > section {
        width:40%;
        text-align:left;
        position:absolute;
        top:40%;
        left:10%;

        h2,h3 {
            font-style: normal;
            font-weight: 800;
            font-size: 35px;
            line-height: 55px;
            color: #000000;
        }

        h3 {
            font-size:30px;
            line-height: 50px;
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 25px;
            letter-spacing: 0.15px;
            color: #000000;
        }
    }

    @media (max-width:900px){
        flex-direction:column;
        gap:50px;

        > section {
            position:relative;
            left:0;
            top:0;
            width:100%;

            > h3 {
                line-height:45px;
            }
        }
    }
`

export const IconHolder = styled.div`
    width:80px;
    height:80px;
    border-radius:10rem;
    display:flex;
    align-items:center;
    justify-content:center;

    > img {
        width:4rem;
    }
`

interface ButtonProps {
    color?: string;
    border?: string;
}

export const TextButton = styled.div<ButtonProps>`
    display:flex;
    align-items:center;
    gap:10px;

    h4 {
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0.15px;
        // text-decoration-line: underline;
        color: ${p => p.color || '#A2AD1F'};
        border: ${p => p.border || 'none'};
        border-radius:8px;
        padding:10px;
    }

    > i {
        margin: 0.4rem 0 0 0;
    }
`

interface CardSectionProps {
    bg?: string;
    imgSize?: string;
    margin?: string;
}

export const CardSection = styled.div<CardSectionProps>`
    padding:1rem 0 3rem 0;
    margin: ${p => p.margin || '5rem 0'};
    background: ${p => p.bg || '#F2FBFA'};
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center;

    img {
        width: ${p => p.imgSize || '40%'};
        object-fit:cover;
    }

    @media (max-width:728px){
        margin: 0;
        img {
            width:100%;
        }
    }
`;

export const ItemCard = styled.div`
    background: #F8F9FC;
    box-shadow: 0px 4px 20px rgba(9, 44, 76, 0.1);
    border-radius: 8px;
    padding:0.2rem 5% 0rem 5%;
    margin: 0 0 1rem 0;

    > h4 {
        font-weight: 700;
        font-size: 18px;
        letter-spacing: 0.15px;
        color: #FFAB01;
    }

    > span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.15px;
        color: #000000;
    }
`