import React from 'react';
import AppHeader from '../components/reusable/header';
import WaitlistField from '../components/contactComp/waitlist';

const Waitlist = () => {
    return(
        <>
            <div className="main-widget">
                    <AppHeader 
                        logo={'/mongoro.png'} 
                        top='0.5rem'
                        hideContact={true} 
                        width='10rem'
                        mobileWidth='8rem'
                    />
                <WaitlistField />
            </div>
        </>
    )
} 

export default Waitlist;