import React, { useContext, useEffect } from "react";
import HomeHero from "../components/homeComps/homeHero";
import Services from "../components/homeComps/services";
import People from "../components/homeComps/people";
import Tools from "../components/homeComps/tools";
import BlogSection from "../components/homeComps/blog";
import Footer from "../components/reusable/footer";
import DownloadSection from "../components/homeComps/downloadSection";
import { variableManager } from "../context/VariableContext";

const Home = () => {
  const { selectProduct, setSelectProduct } = useContext(variableManager);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        className="main-widget"
        onClick={() =>
          setSelectProduct({ ...selectProduct, view: false, actionFrom: "" })
        }
      >
        <HomeHero />
        <Services />
        <Tools />
        <People />
        <BlogSection />
        <DownloadSection />
        <Footer />
      </div>
    </>
  );
};

export default Home;
