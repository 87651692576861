import React from 'react';
import { FlexedData, ServiceMain, CardSection, ItemCard, TextButton } from '../../styles/services';
import { GenericTextHeader } from '../../styles/reusable';

const Tools = () => {
    return(
        <>
            <ServiceMain>
                <CardSection bg={`url('/images/paper.png')`}>
                    <GenericTextHeader>
                        More  
                            <span
                                style={{
                                    color: '#ffab01'
                                }}
                            > tools to grow </span>
                        your Business
                    </GenericTextHeader>
                    <FlexedData
                        style={{
                            justifyContent: 'center',
                            gap: '30px'
                        }}
                    >
                        <section>
                            {
                                data.map((item, index) => (
                                    <ItemCard key={index}>
                                        <h4>{item.title}</h4>
                                        <span>{item.text}</span>
                                        <TextButton
                                            color={item.ready ? '#216FED' : ''}
                                        >
                                            <h4>{item.availText}</h4>
                                        </TextButton>
                                    </ItemCard>
                                ))
                            }
                        </section>
                        <img 
                            src='http://res.cloudinary.com/dszrk3lcz/image/upload/v1677585557/ld34kr6jtc0oj4acx0u1.png'
                            alt='Card'
                        />
                    </FlexedData>
                </CardSection>
                
            </ServiceMain>
        </>
    )
}

export default Tools;

const data = [
    {
        title: 'An Account for you Business',
        text: 'Signing up takes just a few minutes and you can start accepting and making payments right away. Plus, managing your business account is a breeze with our convenient smartphone app.',
        availText: 'Coming Soon',
        ready: false
    },
    {
        title: 'Secure payment links',
        text: 'Start sharing links with your customers to receive payment today - whether you offer home deliveries or you are selling on social media, our payment links helps you get paid easily and securely',
        availText: 'Coming Soon',
        ready: false
    },
    {
        title: 'XInstant payments with QR Code',
        text: 'With MogoroQR, you can generate a Visa QR code on your social media page, create a Mongoro account, and start accepting real payments, all within minutes!',
        availText: 'Coming soon',
        ready: false
    }
]