import React from 'react';
import { CopyRightText, FooterAbout, FooterFlex, FooterMain, FooterNavigations, FooterSocialWrap, RoundedSheet } from '../../styles/footer';
import * as Icon from 'react-feather';
import {useNavigate} from 'react-router-dom';
import { LogoImage } from '../../styles/headerStyle';
import { Link } from 'react-router-dom';

interface PropsArgs {
    scrollToElement?: any;
    scrollToAbout?: any;
}

const Footer = ({scrollToElement, scrollToAbout} : PropsArgs) => {

    const navigate = useNavigate();
    const date = new Date();
    let year = date.getFullYear();

    const handleToContact = () => {
        navigate('/contact');
    }

    const quickLinks = [
        {
            name: 'About',
            url: '',
            action: scrollToAbout
        },
        {
            name: 'Contacts',
            url: '/contact',
            action: handleToContact
        },
        {
            name: 'Help',
            url: '',
        },
        {
            name: 'Partners',
            url: '',
            action: scrollToElement
        },
        
    ]

    return(
        <>
            <FooterMain>
                <FooterFlex>
                    <FooterAbout>
                        <LogoImage 
                            width={'10rem'}
                            src={'/mongoro.png'} 
                            top='0.5rem'
                            mobileWidth='8rem'
                        />
                    </FooterAbout>
                    <FooterNavigations>
                        <div>

                            {
                                quickLinks.map((item, index) => (
                                    <p 
                                        key={index}
                                        onClick={() => {
                                            item.action();
                                        }}
                                    >{item.name}</p>
                                ))
                            }
                        </div>
                        <div>
                            {
                                usefulLinks.map((item, index) => (
                                    <Link to={item.url}>
                                        <p key={index}>{item.name}</p>
                                    </Link>
                                ))
                            }
                        </div>
                        <div>
                            {
                                lastRow.map((item, index) => (
                                    <p key={index}>{item.name}</p>
                                ))
                            }
                        </div>
                    </FooterNavigations>
                </FooterFlex>
                <FooterFlex 
                    align='center'
                    style={{
                        margin: '4rem 0 0 0'
                    }}
                >
                    <CopyRightText>
                        © {year}, Reef Financial Solutions Ltd | All Rights Reserved 
                    </CopyRightText>
                    <FooterSocialWrap>
                        <div>
                            <Icon.Facebook size={15} strokeWidth={2} />
                        </div>
                        <div>
                            <Icon.Twitter  size={15} strokeWidth={2} />
                        </div>
                        <div>
                            <Icon.Instagram  size={15} strokeWidth={2} />
                        </div>
                        <div>
                            <Icon.Linkedin  size={15} strokeWidth={2} />
                        </div>
                    </FooterSocialWrap>
                </FooterFlex>
                
                <RoundedSheet></RoundedSheet>
            </FooterMain>
        </>
    )
}

export default Footer;

const usefulLinks = [
    {
        name: 'Developer',
        url: '',
        action: ''
    },
    {
        name: 'Business Guide',
        url: '',
        action: ''
    },
    {
        name: 'Terms and Condition',
        url: '/terms-condition',
        action: ''
    },
    {
        name: 'Privacy Policy',
        url: '/privacy-policy',
        action: ''
    },
    {
        name: 'Blogs',
        url: '',
        action: ''
    },
]

const lastRow = [
    {
        name: 'Business Tools',
        url: '',
        action: ''
    },
    {
        name: 'Fees',
        url: '',
        action: ''
    },
    {
        name: 'Legal',
        url: '',
        action: ''
    },
    {
        name: 'Careers',
        url: '',
        action: ''
    },
]