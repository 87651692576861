import React, { useEffect } from "react";
import PrivacyHero from "../components/termsComp/privacyHero";
import Footer from "../components/reusable/footer";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="main-widget">
        <PrivacyHero />
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicy;
