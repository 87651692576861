import React from "react";
import "./styles/App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Contact from "./pages/contact";
import Waitlist from "./pages/waitlist";
import Terms from "./pages/terms";
import PrivacyPolicy from "./pages/privacy-policy";
import VaraiableContext from "./context/VariableContext";
import BusinessPrivacyPolicy from "./pages/biz-privacy-policy";

function App() {
  return (
    <>
      <VaraiableContext>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/waitlist" element={<Waitlist />} />
            <Route path="/terms-condition" element={<Terms />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/business-privacy-policy"
              element={<BusinessPrivacyPolicy />}
            />
          </Routes>
        </Router>
      </VaraiableContext>
    </>
  );
}

export default App;
